
import { defineComponent, ref, watch } from "vue";
export default defineComponent({
  name: "SortOption",
  props: {
    orderBy: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
  },
});
