/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { get } from "@/api/http";
import {
  whaleFeed,
  whales,
  nBlueChips,
  accountWallet,
  accountWalletContracts,
  accountContracts,
  accountHistory,
} from "@/api/api";
import { successCallback, errorCallback } from "@/scripts/constants";

const getWhaleFeed = (params?: any) => {
  return get(whaleFeed, { ...params }).then(successCallback).catch(errorCallback)
};

const getWhales = (params?: any) => {
  return get(whales, { ...params }).then(successCallback).catch(errorCallback)
};

const getBlueChips = (params?: any) => {
  return get(nBlueChips, { ...params }).then(successCallback).catch(errorCallback)
};

const getAccountWalletContracts = (params?: any) => {
  return get(accountWalletContracts(params?.walletAddr), { ...params }).then(successCallback).catch(errorCallback)
};

const getAccountWallet = (params?: any) => {
  return get(accountWallet(params?.walletAddr), { ...params }).then(successCallback).catch(errorCallback)
};

const getAccountContracts = (params?: any) => {
  return get(accountContracts(params?.walletAddr, params?.contractAddr), { ...params }).then(successCallback).catch(errorCallback)
};

const getAccountHistory = (params?: any) => {
  return get(accountHistory(params?.walletAddr), { ...params }).then(successCallback).catch(errorCallback)
};

export {
  getWhaleFeed,
  getWhales,
  getBlueChips,
  getAccountWallet,
  getAccountWalletContracts,
  getAccountContracts,
  getAccountHistory,
}
