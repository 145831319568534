import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mx-1 flex flex-col scale-35 transform w-1 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ["relative flex items-center", {
      'text-white': _ctx.orderBy === _ctx.options[1] || _ctx.orderBy === _ctx.options[0],
    }]
  }, [
    _createVNode("div", null, _toDisplayString(_ctx.label), 1),
    _createVNode("div", _hoisted_1, [
      _createVNode("i", {
        class: ["icon-up mb-0.5 font-normal", {
          'text-white': _ctx.orderBy === _ctx.options[1],
          'text-dark-9A': _ctx.orderBy !== _ctx.options[1],
        }],
        style: {"font-size":"0.75rem"}
      }, null, 2),
      _createVNode("i", {
        class: ["icon-down mb-0.5 font-normal", {
          'text-white': _ctx.orderBy === _ctx.options[0],
          'text-dark-9A': _ctx.orderBy !== _ctx.options[0],
        }],
        style: {"font-size":"0.75rem"}
      }, null, 2)
    ])
  ], 2))
}