
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from "vue";
import Navagation from "@/components/commons/Navagation.vue";
import Tabs from "@/components/commons/Tabs.vue";
import ListItem from "@/components/commons/list/ListItems.vue";
import Loading from "@/components/commons/Loading.vue";
import SortOption from "@/components/commons/SortOption.vue";
import sort from "@/assets/nfts/sort.webp";
import { historyReplaceState } from "@/utils/url";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { getWhaleFeed, getWhales, getBlueChips } from "@/model/tracking";

export default defineComponent({
  name: "NFT",
  components: {
    Navagation,
    Tabs,
    ListItem,
    Loading,
    SortOption,
  },
  setup() {
    const { t } = useI18n();
    const pageNum = ref(0);
    const pageSize = ref(12);
    const loadNext = ref();
    const showLoadMore = ref(true);
    const route = useRoute();
    const selectIndex = ref(0);
    const routerArray = ["tracking", "rank", "next-blue-chip"];
    let sIndex = route?.params?.index + "";
    if (routerArray.indexOf(sIndex) > -1) {
      selectIndex.value = routerArray.indexOf(sIndex);
    } else {
      selectIndex.value = Number(route?.params?.index || 0);
    }

    const dataList: any = ref([]);
    const loading = ref(true);
    const cursorId = ref("0");
    const rankOrderBy = ref("return_desc");
    const blueChipOrderBy = ref("bc_owner_rate_desc");
    let deDupArray: string[] = [];
    const getData = () => {
      if (selectIndex.value == 1) {
        getWhales({
          txCount: 1,
          pageNum: pageNum.value,
          pageSize: pageSize.value,
          orderBy: rankOrderBy.value, //return_asc value_asc value_desc
        }).then((res) => {
          if (res?.data.length === 0) {
            showLoadMore.value = false;
          } else {
            pageNum.value += 1;
          }
          loading.value = false;
          dataList.value = dataList.value.concat(res?.data || []);
        });
      } else if (selectIndex.value == 2) {
        getBlueChips({
          pageNum: pageNum.value,
          pageSize: pageSize.value,
          orderBy: blueChipOrderBy.value,
        }).then((res) => {
          if (res?.data.length === 0) {
            showLoadMore.value = false;
          } else {
            pageNum.value += 1;
          }
          loading.value = false;
          dataList.value = dataList.value.concat(res?.data || []);
        });
      } else {
        getWhaleFeed({
          pageNum: pageNum.value,
          pageSize: pageSize.value,
          cursorId: cursorId.value,
        }).then((res) => {
          cursorId.value = res?.cursorId;
          if (res?.cursorId === "0" || res?.data.length === 0) {
            showLoadMore.value = false;
          } else {
            pageNum.value += 1;
          }
          loading.value = false;
          dataList.value = dataList.value.concat(res?.data || []);
        });
      }
    };
    getData();

    const resetParams = () => {
      cursorId.value = "0";
      dataList.value = [];
      pageNum.value = 0;
      loading.value = true;
      deDupArray = [];
      showLoadMore.value = true;
    };
    const tabChange = (index: number) => {
      historyReplaceState("/", "" + routerArray[index]);
      selectIndex.value = index;
      resetParams();
      getData();
    };
    const handleOrderBy = (order: string) => {
      const sort = { desc: "asc", asc: "desc" };
      const orderBy = selectIndex.value == 1 ? rankOrderBy : blueChipOrderBy;
      let byArray = orderBy.value.split("_");
      let last = byArray[byArray.length - 1];
      const left = orderBy.value.split("_" + last);
      orderBy.value = order + "_" + (left[0] != order ? "desc" : sort[last]);
      resetParams();
      getData();
    };

    let ob: any;
    onMounted(() => {
      ob = new IntersectionObserver(
        (entries) => {
          // When intersectionRatio is equal to 0, it means that the observed element does not enter the view.
          if (entries[0].intersectionRatio === 0) return;
          getData();
        },
        {
          rootMargin: "200px",
          threshold: [0],
        }
      );
      ob.observe(loadNext.value as HTMLElement);
    });
    onBeforeUnmount(() => {
      ob.disconnect();
    });

    return {
      selectIndex,
      tabChange,
      sort,
      dataList,
      loading,
      loadNext,
      showLoadMore,
      rankOrderBy,
      blueChipOrderBy,
      handleOrderBy,
    };
  },
});
