
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from "vue";

export default defineComponent({
  name: "Tabs",
  props: {
    selectIndex: {
      type: Number,
      default: 0,
    },
    tabArray: {
      type: Array,
      default: () => [],
    },
    showBackground: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const handleTabChange: (index: number) => void = (index) => {
      if (index !== props.selectIndex) {
        emit("tabChange", index);
      }
    };
    return {
      handleTabChange,
    };
  },
});
